<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    class="missionPart"
    append-to-body
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="120px"
    >
      <div class="tablePart">
        <el-form-item label="行数" prop="rowNum">
          <el-input-number
            v-model="dataForm.rowNum"
            :disabled="disabled"
            placeholder="行数"
          >
          </el-input-number>
        </el-form-item>
        <el-form-item label="列数" prop="colNum">
          <el-input-number
            v-model="dataForm.colNum"
            :disabled="true"
            placeholder="列数"
          >
          </el-input-number>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { dialogMixin, normal } from '@/mixins';

export default {
  mixins: [dialogMixin, normal],
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: 0,
        rowNum: 1,
        colNum: 4,
      },
      dataRule: {
        rowNum: [{ required: true, message: '行数不能为空', trigger: 'blur' }],
        colNum: [{ required: true, message: '列数不能为空', trigger: 'blur' }],
      },
    };
  },
  created() {},

  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || null;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/cc/shelfstyle/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = { ...data.shelfstyle };
            }
          });
        }
      });
    },
    // 表单提交,只提交部分参数
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/cc/shelfstyle/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: {
              id: this.dataForm.id,
              rowNum: this.dataForm.rowNum,
              colNum: this.dataForm.colNum,
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('success');
            } else if (data && data.status === 422) {
              this.$message({
                message: '操作失败',
                type: 'error',
                duration: 1500,
              });
            }
          });
        }
      });
    },
  },
};
</script>
